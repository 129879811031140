import {computed, Ref} from 'vue'
import api, { EllisApi } from 'src/services/EllisService'
import { Profile } from 'domain/schema/Profile.model'
import {useStore} from 'vuex'

export interface EllisContext {
	api: EllisApi,
	store: any,
	profile: Ref<Profile>,
}

export default function useEllisContext(): EllisContext {
	const store = useStore()
	const profile = computed(() => store.state.profile)

	return {
		api,
		store,
		profile,
	}
}