<template>
	<cam-box class="el-member-dashboard" p="2">
		<!-- <map-house-members/> -->
		<input type="text" v-model="address" placeholder="Address or Zip Code"/>
		<br>
		<cam-box :key="rerender">
			<CivicOffice v-for="(o, i) in offices" :office="o" :key="i"/>
		</cam-box>
	</cam-box>
</template>

<script lang="ts">
import { ref, computed } from 'vue'
import {debounce} from 'lodash'
import { computedAsync } from '../../util/computed'
import civicService from 'src/services/CivicService.ts'
import CivicOffice from 'src/components/member/CivicOffice.vue'
// import MapHouseMembers from 'src/map/map-house-members.vue'

export default {
	name: 'el-member-dashboard',
	components: {CivicOffice},
	// components: {MapHouseMembers},
	setup(p, {root}) {
		const rerender = ref(false)
		const address = ref('80241')

		const fetchReps = debounce((address: string) => {
			return civicService.representativeInfoByAddress(address).then((response) => {
				rerender.value = !rerender.value
				return response
			})
		}, 300)

		const myReps = computedAsync(async () => {
			let response = null
			try {
				response = await fetchReps(address.value)
			} catch (ex) {
				response = []
			}

			return response?.offices?.map((office) => ({
				...office,
				officials: office.officialIndices.map((idx) => response.officials[idx])
			}))
		})

		const offices = computed(() => {
			return myReps.value?.filter((o) => o.name.includes('Senator') || o.name.includes('Representative'))
		})

		return {
			rerender,
			address,
			offices,
		}
	},
}
</script>

<style lang="stylus" scoped>
</style>