import { fetchJson } from 'lib/http/Http'

export default (function CivicService(apiKey: string) {
	const baseUrl = `https://www.googleapis.com/civicinfo/v2`

	async function representativeInfoByAddress(address: string, levels: string[] = ['country']) {
		if(!address) return null
		const qsLevels = levels.join(',')
		return await fetchJson(`${baseUrl}/representatives?address=${address}&levels=${qsLevels}&key=${apiKey}`)
	}

	return {
		representativeInfoByAddress,
	}
})(process.env.VUE_APP_GOOGLE_API_KEY)