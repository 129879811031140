import {ref, Ref, UnwrapRef, watchEffect} from 'vue'

export function computedAsync<T>(fn: () => T | Promise<T>, initial?: T): Ref<UnwrapRef<T>> {
	const result = ref<T>(initial)
	watchEffect(() => {
		const invoke: any = fn()
		if(invoke?.then) {
			invoke.then((r) => result.value = r as UnwrapRef<T>)
		} else {
			result.value = invoke || initial
		}
	})

	return result
}
