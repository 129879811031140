<template>
	<cam-box flex="flex-start" dir="column">
		<div class="member-chip" v-if="member">
			<router-link :to="`/members/${member.member_id}`">
				<el-rep-photo :size="size" :rep-id="id"/>
			</router-link>
			<div v-if="!thumbnail">
				<router-link :to="`/members/${member.member_id}`">
					<h4>{{member.first_name}} {{member.last_name}}</h4>
					<h6>{{roleTitle}}</h6>
				</router-link>
				<span>{{party}} from&nbsp;</span>
				<span>{{member.roles[0].state}}</span>
			</div>
		</div>
		<slot></slot>
	</cam-box>
</template>

<script lang="ts">
import {computedAsync} from 'src/util/computed'
import { computed } from 'vue'
import useEllisContext from 'src/useEllisContext'
import ElRepPhoto from './el-rep-photo.vue'
import {Party} from 'domain/Constants'

export default {
	name: 'member-chip',
	components: {ElRepPhoto},
	props: {
		m: {type: Object, default: null},
		id: {type: String, required: true},
		thumbnail: {type: Boolean, default: false},
		size: {type: String},
	},
	setup(props) {
		const {api} = useEllisContext()
		const fetchMember = (id) => api.member.get(id)

		const member = computedAsync<any>(() => fetchMember(props.id))
		const party = computed(() => Party[member.value?.current_party])
		const roleTitle = computed(() => member.value.roles[0]?.title)

		return {
			member,
			party,
			roleTitle,
		}
	},
	methods: {
		routeToMember() {
			// this.$route.name !== 'representative' && this.$router.push({name: 'representative', params: {memberId: this.member.id}})
		},
	},
}
</script>

<style lang="stylus" scoped>
@require '~style/mixins.styl'

.member-chip {
	flexXY(flex-start, center)
	flexGutter(16px)
}
</style>
