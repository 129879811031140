<template>
	<div class="el-rep-photo">
		<div class="container thumbnail" :style="{
			width: size,
			height: size,
		}">
			<img v-if="!notFound" :src="`https://theunitedstates.io/images/congress/225x275/${repId}.jpg`"
				:style="{
					minWidth: size,
					minWeight: size,
				}"
				@error="() => notFound = true">
			<i v-else class="not-found fas fa-user-tie"></i>
		</div>
	</div>
</template>

<script lang="ts">
export default {
	name: 'el-rep-photo',
	props: {
		repId: {type: String},
		party: {type: String},
		size: {type: String, default: '8rem'}
	},
	data: () => ({
		notFound: false,
	}),
}
</script>

<style lang="stylus" scoped>
@require '~style/mixins.styl'
@require '~style/variables.styl'

.el-rep-photo {
	position: relative
	.container {
		border-radius: 100%
		neu(white, 1)
		flexXY(center, center)
		overflow: hidden
		img {
			position: relative
			background-blend-mode: overlay
			width: 9vw
			min-width: 72px
			min-height: 72px
		}
		i.not-found {
			font-size: 6em
		}

		&.thumbnail {
			min-width: 54px
			min-height: 54px
			img {
				width: 100%
				min-width: 54px
				min-height: 54px
			}
			.party-icon {
				absPos(0, auto, auto, 0)
				circle(12px)
			}
		}
	}

	.party-icon {
		absPos(4px, auto, auto, 4px)
		circle(20px)
		z-index: 1
		border: 2px solid #fff
	}
}
</style>
