<template>
	<div class="civic-office">
		<h4>{{name}}</h4>
		<cam-box flex="space-evenly" wrap>
			{{office}}
			<!-- <cam-box v-for="(o, i) in office.officials" :key="i">
				<el-member-chip :id="ellisMembers[i].id"/>
			</cam-box> -->
		</cam-box>
	</div>
</template>

<script lang="ts">
import { computed, inject } from 'vue'
import { computedAsync } from '../../util/computed'
import { EllisApi } from '../../services/EllisService'
import elMemberChip from './el-member-chip.vue'

function shorthandTitle(title: string) {
	if(title.toLowerCase().indexOf('governor') === 0) return 'Governor'
	if(title.toLowerCase().indexOf('lieutenant governor') === 0) return 'Lieutenant Governor'
	switch (title.toLowerCase()) {
		case 'president of the united states': return 'President'
		case 'vice president of the united states': return 'Vice President'
		default: return title
	}
}

export default {
  components: { elMemberChip },
	name: 'civic-office',
	props: {
		office: {type: Object, required: true},
	},
	setup(p: {office: any}) {
		const api: EllisApi = inject('api')

		const display = {
			name: computed(() => shorthandTitle(p.office.name)),
		}
		const ellisMembers = computedAsync(() => Promise.all(
			p.office?.officials?.map((o) => {
				const names = o?.name.split(' ')
				return api.member.getByName(names[0], names[names.length - 1])
			})
		))

		return {
			...display,
			ellisMembers,
		}
	},
}
</script>

<style lang="stylus" scoped>
.civic-office {

}
</style>