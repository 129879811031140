
export default {
	name: 'el-rep-photo',
	props: {
		repId: {type: String},
		party: {type: String},
		size: {type: String, default: '8rem'}
	},
	data: () => ({
		notFound: false,
	}),
}
