
import {computedAsync} from 'src/util/computed'
import { computed } from 'vue'
import useEllisContext from 'src/useEllisContext'
import ElRepPhoto from './el-rep-photo.vue'
import {Party} from 'domain/Constants'

export default {
	name: 'member-chip',
	components: {ElRepPhoto},
	props: {
		m: {type: Object, default: null},
		id: {type: String, required: true},
		thumbnail: {type: Boolean, default: false},
		size: {type: String},
	},
	setup(props) {
		const {api} = useEllisContext()
		const fetchMember = (id) => api.member.get(id)

		const member = computedAsync<any>(() => fetchMember(props.id))
		const party = computed(() => Party[member.value?.current_party])
		const roleTitle = computed(() => member.value.roles[0]?.title)

		return {
			member,
			party,
			roleTitle,
		}
	},
	methods: {
		routeToMember() {
			// this.$route.name !== 'representative' && this.$router.push({name: 'representative', params: {memberId: this.member.id}})
		},
	},
}
